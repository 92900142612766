import React, {Component} from 'react';
import {Link} from 'gatsby';
import {graphql} from 'gatsby';
import BaseLayout from '../components/Layout/BaseLayout';


class SiteMapPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

  }

  componentDidMount() {
    this.setState({
      show: true
    });
  }


  render() {

    const { data, location } = this.props;
    return (
      <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}>
        <h1>{data.gravql.page.title}</h1>

        <ul className="sitemap">
          {data.gravql.pages.map(child => (

              child.visible &&

                    <li key={child.title}><Link to={child.route}>{child.title}</Link>
              {child.children.length !== 0 && child.header[0].body_classes !== "no-submenu" && (<ul>
                {child.children.map(child => (
                  <li key={child.title}><Link to={child.route}>{child.title}</Link>
                    {child.children.length !== 0 && child.header[0].body_classes !== "modal-children" && (<ul>
                      {child.children.map(child => (
                        <li key={child.title}><Link to={child.route}>{child.title}</Link></li>
                      ))}
                    </ul>)}
                  </li>
                ))}
              </ul>)}
            </li>
          ))}
        </ul>

      </BaseLayout>
    )
  }
}

export default SiteMapPage;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                    header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            header {
                title
                body_classes
            }
            metadata {
                name
                content
            }   
        }
    }
  }
`
